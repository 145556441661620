/* Calendar */

#calendarioPrincipal > .react-calendar {
  height: 40vh;
}

#calendarioPrincipal .react-calendar__navigation {
  justify-content: center;
  margin-bottom: 20px;
}

#calendarioPrincipal .react-calendar__navigation > button {
  border: none;
  background-color: transparent;
  flex-grow: 0 !important;
  color: #002858;
  text-transform: uppercase !important;
  font-weight: bold;
  font-size: 22px;
  margin-right: 15px;
}

#calendarioPrincipal .react-calendar__navigation__arrow {
  color: #0075c9 !important;
}

#calendarioPrincipal .react-calendar__viewContainer,
.react-calendar__month-view,
.react-calendar__month-view__days,
.react-calendar__month-view > div,
.react-calendar__month-view > div > div {
  height: 100% !important;
}

#calendarioPrincipal .react-calendar__month-view__weekdays__weekday {
  color: #002858;
  font-size: 24px;
  font-weight: bold;
  justify-content: center;
  display: flex;
  text-transform: uppercase;
}

#calendarioPrincipal .react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none !important;
}

#calendarioPrincipal .react-calendar__month-view__days__day {
  justify-content: center;
  display: flex;
  font-size: 22px !important;
  font-weight: bold !important;
  color: #0075c9 !important;
  background-color: transparent;
  border: none;
  border-radius: 30px;
  height: 69px;
  padding: 1%;
}

#calendarioPrincipal .react-calendar__month-view__days__day > abbr {
  align-self: center;
}

#calendarioPrincipal .react-calendar__month-view__days__day:hover {
  background-color:  #0075c9!important;
  transition: all 250ms cubic-bezier(0.45, 0.05, 0.55, 0.95) 0.1s !important;
  box-shadow: 0px 1px 9px 0px #9e9e9e;
  color: white !important;
  cursor: pointer !important;
  border-radius: 30px;
}

#calendarioPrincipal .react-calendar__month-view__days__day:active {
  background-color:  #0075c9!important;
}

#calendarioPrincipal .appointment {
  background-color:  #ededed!important;
  color:#002e60 !important;
}

#calendarioPrincipal .activeDay{
  background-color: #0075c9!important;
  color: white!important;
}