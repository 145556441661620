.MuiDialog-paperWidthSm > #modalProfile  {
    max-width: 550px !important;
    width: 500px;
}

.MuiDialog-paperWidthSm > #cancelModal  {
    width: 100% !important;
}
.MuiDialog-container > .MuiDialog-paperWidthSm > #cancelModal {
    display: flex !important;
    justify-content: flex-start !important;
}

#backDrop > .MuiDialog-container > .MuiDialog-paperWidthSm {
    border-radius: 20px !important;
    margin-top: -150px;

}
#modalTurn > .MuiDialog-container > .MuiDialog-paperWidthSm,
#maintenanceModal > .MuiDialog-container > .MuiDialog-paperWidthSm {
    border-radius: 20px !important;
}

#backDrop{
    margin-top: 260px;
}

#backDrop >.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0) !important;
    backdrop-filter: blur(3px);
}
#modalTurn >.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0) !important;
    backdrop-filter: blur(3px);
}

#maintenanceModal >.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, .7) !important;
    backdrop-filter: blur(10px);
}

@media only screen and (min-height:720px) and (max-height: 1440px) {
    #backDrop{
        margin-top: 250px;
    }
  }

@media only screen and (min-width:0px) and (max-width: 960px) {
    #backDrop > .MuiDialog-container {
        flex-direction: column;
        align-items: stretch;
    }
    #backDrop > .MuiDialog-container > .MuiDialog-paperWidthSm {
        border-radius: 0px !important;
        margin: 20px !important;
        height: 70% !important;
        width: 90%  !important;
    }
    .MuiDialog-paperWidthSm > #modalProfile  {
        max-width: 100% !important;
        height: 100%;
    }

    .MuiDialog-paperWidthSm > #cancelModal  {
        max-width: 100% !important;
        height: 100%;
        justify-content: center;
    }
    #backDrop{
        margin-top: 135px;
    }
    #modalTurn{
        display: flex;
        justify-content: center;
        left: 0px;
    }
  }
