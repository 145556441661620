#backDropActivities >.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0) !important;
    backdrop-filter: blur(3px);
    
}
#backDropActivities > .MuiDialog-container > .MuiDialog-paperWidthSm {
    border-radius: 20px !important;
}
.MuiDialog-paperWidthSm > #modalActivities  {
    max-width: 100% !important;
}