body{
    margin: 0px;
}
.loading-enter{
    opacity: 0;
}

.loading-enter-active{
    opacity: 1;
    transition: opacity 50ms ease-in;
}

.loading-exit{
    opacity: 1;
}

.loading-exit-active{
    opacity: 0;
    transition: opacity 5000ms ease-in;
}

.content{
    background-color: white; 
    padding-left: 265px; 
    padding-top: 80px;
    height: 100vh;
}

@media screen and (max-width: 768px) {
    .content{ 
        padding-left: 0px;
    }
}

@media screen and (min-width: 1367px) {
    .content{
        padding-left: 340px; 
    }
}

.poi-info-window div {
    padding: 5px;
}
