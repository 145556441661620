.select-doctor-accordion-menu-item-orange {
  --select-item-point-color: #ffba3a;
}

.select-doctor-accordion-menu-item-green {
  --select-item-point-color: #6bf36b;
}

.select-doctor-accordion-menu-item-red {
  --select-item-point-color: #A51C30;
}
