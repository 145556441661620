#modalCredentials {
    --credential-card-text-display: flex;
}

#modalCredentials > .MuiDialog-container > .MuiPaper-root {
    border-radius: 15px !important;
    margin:0;
    width:100%;
}

#modalCredentials >.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0) !important;
    backdrop-filter: blur(3px);
}

.home-credential{
    --credential-card-text-display: block;
}

.credential-card-text{
    display: var(--credential-card-text-display);
    flex-direction: column;
    align-items: flex-start;

}

.qr-credential{
    transform: var(--qr-scale-transform);
    padding-top: var(--qr-scale-padding-top);
    padding-left: 0;

}

@media only screen and (min-width:0px) and (max-width: 400px) {

    #modalCredentials > .MuiDialog-container > .MuiPaper-root{
        height: 90vw;
   } 
 } 

 @media only screen and (min-width:400px) and (max-width: 500px) {
    #modalCredentials > .MuiDialog-container.MuiDialog-scrollPaper {
        display: block;
        height: 80vw;
    }
 }
 @media only screen and (min-width:0px) and (max-width: 500px) {
     #modalCredentials{
        --qr-scale-transform: scale(1.5);
        --qr-scale-padding-top: 5vw;

        width: 100vh !important;
        height: 100vh !important;        
        transform: rotate(270deg);
        padding-right: 5%;
        padding-left: 5%;
        padding-top: 2.5%;
    } 

    #modalCredentials > .MuiDialog-container.MuiDialog-scrollPaper {
        display: block;
    }

     #modalCredentials > .MuiDialog-container >.MuiPaper-root{
        max-width: 100%;
    } 

    .home-credential{
        --qr-scale-padding-left: 15vw;
    }
    
  } 


  @media only screen and (min-height:800px) {

    #modalCredentials{

        padding-right: 20% !important;
        padding-left: 20% !important;
    } 

    .credential-text-bottom{
        padding-top: 0px !important;
    }
  }
  @media only screen and (min-width:0px) and (max-width: 767px) and (orientation: landscape) {
    #modalCredentials > .MuiDialog-container {
        height: 100vw; 
        width: 100vw;
        display: block;
    }
    #modalCredentials > .MuiDialog-container >.MuiPaper-root{
        width: 100vw !important;
        height: 100vw !important;
        max-width: 100%;
        margin:0px !important;
    }
  }

  @media only screen and (min-width:0px) and (max-width: 768px) {
    .qr-credential {
        padding-left: 16%;
    }
 } 