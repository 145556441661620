.MuiAutocomplete-popper>div{
    background-color: #f3f3f3;
    color: #0075c9;
}


.MuiAutocomplete-popper > div > ul > li {
    overflow-y: auto;
    border-bottom: 1px solid #e2e3e3;
    font-family: "Arial", sans-serif;
    font-size: 12px !important;
    font-weight: 800;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 4px;
    padding-bottom: 0px;
  }
  