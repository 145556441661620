#accordion > div >.MuiAccordionSummary-root{
    height: 80px;
}

.notification.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root{
    padding: 10px 5px 10px 5px !important;
	display: block !important;
}
.MuiAccordionDetails-root{
    padding: 0px 16px 16px !important;
	display: block !important;
}


.MuiAccordionSummary-expandIcon.Mui-expanded{
    transform: rotate(90deg) !important;

}
.MuiAccordionSummary-root> .MuiButtonBase-root> .MuiIconButton-label> .MuiSvgIcon-root {
	height: 0.5em !important;
	width: 0.5em !important;
	color: #0075C9;
}


@media (max-width: 400px) {
	#accordion> div>.MuiAccordionSummary-root {
		min-height: 40px;
	}
}

@media (max-width: 600px) {
	#accordion> div>.MuiAccordionSummary-root {
		height: 80px !important;

	}
	.notification.MuiAccordion-root.Mui-expanded {
		border-radius: 0px !important;
	}
	.MuiAccordion-root.Mui-expanded {
		border-radius: 20px !important;
	}
}

@media only screen and (min-width:600px) and (max-width: 960px) {
	#accordion> div>.MuiAccordionSummary-root {
		height: 80px !important;
	}
	.notification.MuiAccordion-root.Mui-expanded {
		border-radius: 0px !important;
	}
	.MuiAccordion-root.Mui-expanded {
		border-radius: 20px !important;
	}
}