.MuiPicker-root .MuiDateTimePickerTabs-tabs{
    background-color: #FFF !important;
}

.MuiPicker-root .css-1p18c5y-MuiTabs-indicator{
    /* barritahorizontal */
    background-color: #002858 !important;
}
.MuiPicker-root .css-1h08vn0-MuiButtonBase-root-MuiTab-root.Mui-selected{
    /*calendarIcon*/
    color: #0075c9 !important;
}
.MuiPicker-root .MuiPickersDay-root.Mui-selected{
    /*dia seleccionado*/
    background-color: #0075c9 !important;
}

.MuiPickersModalDialog-action .css-d0ukip-MuiButtonBase-root-MuiButton-root{
    color: #0075c9 !important;
}

.MuiClock-meridiemButtonSelected{
    background-color:#0075c9 !important;
    color:#fff
}

.MuiClock-meridiemButtonSelected{
    background-color: #0075c9 !important;
}
.MuiClockPointer-noPoint{
    background-color: #0075c9 !important;

}

.MuiClockPointer-root{
    background-color: #0075c9 !important;
}

.MuiClockPointer-thumb{
    border: 16px solid #0075c9 !important;

}

.css-hlj6pa-MuiDialogActions-root{
    justify-content: center !important;
}

.MuiClock-pin{
    background-color: #0075c9 !important;

}

#DateTimePicker .MuiTextField-root {
    margin-left:20px !important;
        top:22px


}

#DateTimePicker .MuiTextField-root .MuiInputBase-input{
    padding: 11px 14px;

}