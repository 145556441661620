[id^="menu"].MuiPopover-root > div.MuiPaper-elevation8 {
  position: absolute;
  background-color: #f3f3f3;
  color: #0075c9;
  max-height: 400px;
}

[id^="menu"].MuiPopover-root > div.MuiPaper-elevation8 > ul > li {
  overflow-y: auto;
  border-bottom: 1px solid #e2e3e3;
  font-family: "Arial", sans-serif;
  font-size: 12px !important;
  font-weight: 800;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 4px;
  padding-bottom: 0px;
}

#filtersMobile-popover > div > div > div.MuiCollapse-container {
  overflow: auto;
  max-height: 179px;
}

#alphabeticalSearch-label + div.MuiInputBase-formControl{
  width: 100%;
}

.makeStyles-textBtnFilter-43 > div.MuiInputBase-formControl > input{
  margin-left: 30px;
  margin-right: 30px;
  font-size: 12px;
}

@media screen and (max-width: 956px) {
  [id^="menu"].MuiPopover-root {
    z-index: 1300 !important;
    top: 50px !important;
    left: 0;
    right: 0;
    bottom: 0;
  }
  [id^="menu"].MuiPopover-root > div.MuiPaper-elevation8 {
    padding-top: 0px !important;
    max-height: 200px;
  }
}



