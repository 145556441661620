/* Calendar */

#calendarioTurnos > .react-calendar {
  height: 100%;
  width: 60%;
  min-width: 250px;
}

#calendarioTurnos .react-calendar__navigation {
  justify-content: center;
  padding-bottom: 10px;
}

#calendarioTurnos .react-calendar__navigation > button {
  color: #0075c9;
  font-size: 18px;
  border: none;
  background-color: transparent;
  flex-grow: 0 !important;
  text-transform: uppercase !important;
  font-weight: bold;
}

#calendarioTurnos .react-calendar__navigation__next-button, .react-calendar__navigation__prev-button {
  font-size: 26px !important;
}

#calendarioTurnos .react-calendar__navigation__arrow {
  color: #0075c9 !important;
}

#calendarioTurnos .react-calendar__viewContainer{
  justify-content: center;
  display: flex;
}

#calendarioTurnos .react-calendar__viewContainer,
.react-calendar__month-view,
.react-calendar__month-view__days,
.react-calendar__month-view > div,
.react-calendar__month-view > div > div {
  height: 100% !important;
}

#calendarioTurnos .react-calendar__month-view__weekdays__weekday {
  font-size: 12px;
  color: #585858 ;
  font-weight: bold;
  justify-content: center;
  display: flex;
  text-transform: uppercase;
}

#calendarioTurnos .react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none !important;
}

#calendarioTurnos .react-calendar__month-view__days__day {
  font-size: 15px !important;
  height: 32px;
  border-radius: 0px;
  justify-content: center;
  display: flex;
  /* font-weight: bold !important; */
  color: #585858  !important;
  background-color: transparent;
  border: none;
}

#calendarioTurnos .react-calendar__month-view__days__day > abbr {
  align-self: center;
}

#calendarioTurnos .react-calendar__month-view__days__day:hover {
  background-color: #002e60 !important;
  transition: all 250ms cubic-bezier(0.45, 0.05, 0.55, 0.95) 0.1s !important;
  box-shadow: 0px 1px 9px 0px #9e9e9e;
  color: white !important;
  cursor: pointer !important;
  border-radius: 30px;
}

#calendarioTurnos .react-calendar__tile--active{
  background-color: #0075c9 !important;
  color: white !important;
}

#calendarioTurnos .appointment {
  background-color: #0075c9;
  color: white !important;
}

#calendarioTurnos .disponible{
  background-color: #40cc40;
  border: 1px solid rgb(245, 244, 244);
  color: white !important;
}

#calendarioTurnos .licencia {
  background-color: red;
  color: white !important;
}

#calendarioTurnos .sinTurno{
  background-color: #e2e3e3;
  color: #585858 !important;
}

#calendarioTurnos .turnoSeleccionado{
  background-color: #40cc40;
  color: white !important;
}

@media only screen and (max-width : 700px) {
  #calendarioTurnos > .react-calendar {
    width: 100%;
  }
}

@media only screen and (max-width : 500px) {
  #calendarioTurnos .react-calendar__month-view__days__day {
    width: 14.2857143% !important;
    max-width: 14.2857143% !important;
    min-width: 14.2857143% !important;
    flex-grow: 0;
    flex-shrink: 0;
  }

  #calendarioTurnos .react-calendar__month-view__weekdays__weekday > abbr {
    text-decoration: none !important;
    width: 14.2857143% !important;
    max-width: 14.2857143% !important;
    min-width: 14.2857143% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-grow: 0;
    flex-shrink: 0;
  }
}