.gm-style .gm-style-iw-c {
    background-color: transparent!important;
    border-radius : 20px;
    opacity: "0.7";
    width: "100%";
    height: "38%";
    max-width: 100% !important;
    /*max-height: 326px !important; */
    min-width: 300px !important; 
    box-sizing: border-box;
    overflow-x: hidden;
    overflow: hidden !important;
    top: 0;
    left: 0;
    padding: 0px !important;
    box-shadow: none !important;
    }

.gm-style-iw-t{

    box-shadow:"1px 12px 20px #585858" !important;
}

    .gm-style-iw-d{
        overflow-x: hidden !important;
        overflow: hidden !important;
    }

    .gm-ui-hover-effect{
        display: none !important;
    }
    .gm-style .gm-style-iw-t::after{
        background: none !important;
    }
    .gmnoprint{
        display: none !important;
    }



    .btn-principal {
        width: 100% !important;
        margin-top: 20px !important;
        position:relative  !important;
    }

    #btnVerListadoMap{
        height: 35px  !important;
        width: 15% !important;
        color: #FFF !important;
        border-radius: 20px !important;
        background: linear-gradient(90deg, #002858 , #0075c9 100%) !important;
    }

    #btnNoEstasOnlineMap{
        height: 35px  !important;
        width: 15% !important;
        color: #FFF !important;
        border-radius: 20px !important;




    }

@media only screen and (max-width:960px ) { 
           
        #btnNoEstasOnlineMap{
            height: 35px  !important;
            width: 30% !important;
            color: #FFF !important;
            border-radius: 20px !important;

    
        }
        #btnVerListadoMap{
            display: none  !important;
            width: 30% !important;
            color: #FFF !important;
            border-radius: 20px !important;

        }
      } 

@media only screen and (max-width:600px ) { 
         
        #btnNoEstasOnlineMap{
            height: 35px  !important;
            width: 35% !important;
            color: #FFF !important;
            border-radius: 20px !important;
    
        }
        #btnVerListadoMap{
            color: #FFF !important;
            border-radius: 20px !important;
            display: none !important;
        }
} 